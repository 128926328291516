module.exports = new Promise ((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let remoteUrlWithVersionMA360;
    
    remoteUrlWithVersionMA360 = "http://localhost:3003/remoteEntry.js";//"https://qa-exlvantage-MA360.exlservice.com/remoteEntry.js";
    const script = document.createElement("script");
    let cookies = document.cookie;
    const cookieArray = cookies.split(";").map((s) => s.split("=").map((s) => s.trim())).reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    const env = cookieArray.get("VANTAGE_ENV");
    console.log("environent ", env);

    switch (env) {
        case "local":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
          break;
        case "dev":
            remoteUrlWithVersionMA360 =
                "https://az03-hc-app-m360-cmn-dev.azurewebsites.net/remoteEntry.js";
            break;
        case "TestAzure":
            remoteUrlWithVersionMA360 =
                "https://vantagetestma360.exlservice.com/remoteEntry.js";
            break;
        case "TWNP":
            remoteUrlWithVersionMA360 =
                "https://m360commonnp3.triwest.com/remoteEntry.js";
            break
        case "TWPRD":
            remoteUrlWithVersionMA360 =
                "https://VantageMA360.triwest.com/remoteEntry.js";
            break;
        case "qa":
            remoteUrlWithVersionMA360 =
                "https://qa-exlvantage-ma360.exlservice.com/remoteEntry.js";
           break;
        case "hznuat":
        case "maxoruat":
            remoteUrlWithVersionMA360 = "https://vantageuatma360.exlservice.com/remoteEntry.js";
            break;
        case "hznprod":
        case "maxorprod":
            remoteUrlWithVersionMA360 = "https://vantageprodma360.exlservice.com/remoteEntry.js";
            break;
        case "lktuat":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "idemo":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktuattest":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktprod":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
            break;
        case "Textron":
            remoteUrlWithVersionMA360 = "https://vantagema360react.exlservice.com/remoteEntry.js";
            break;
        case "CHA":
            remoteUrlWithVersionMA360 = "https://vantagema360react.exlservice.com/remoteEntry.js";
            break;
        case "HAP":
            remoteUrlWithVersionMA360 = "https://vantagema360react.exlservice.com/remoteEntry.js";
            break;
        case "HP":
            remoteUrlWithVersionMA360 = "https://vantagema360react.exlservice.com/remoteEntry.js";
            break;
        case "Idemo":
            remoteUrlWithVersionMA360 = "https://vantagema360react.exlservice.com/remoteEntry.js";
            break;
        case "Cdemo":
            remoteUrlWithVersionMA360 = "https://vantagema360react.exlservice.com/remoteEntry.js";
            break;
        case "Maxor":
            remoteUrlWithVersionMA360 = "https://vantagema360reactlive.exlservice.com/remoteEntry.js";
            break;
        default:
            remoteUrlWithVersionMA360 = "";
            break;
    }
    script.src = remoteUrlWithVersionMA360;
    script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
            get: (request) => window.MA360.get(request),
            init: (arg) => {
                try {
                    return window.MA360.init(arg);
                } catch (e) {
                    console.log("remote container already initialized");
                }
            },
        };
        resolve(proxy);
    };
    script.onerror = () => {
        reject();
        //alert("Error loading " + this.src); // Error loading https://example.com/404.js
        document.head.appendChild(null);
        //return null;
    };
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);
});