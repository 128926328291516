module.exports = new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let remoteUrlWithVersion;
    let cookies =  document.cookie;
    const cookieArray = cookies.split(";").map((s) => s.split("=").map((s) => s.trim())).reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    const env =cookieArray.get("VANTAGE_ENV");
    console.log("environent ", env);
    switch (env) {
        case "local":
            remoteUrlWithVersion = "http://localhost:3000/remoteEntry.js";
          break;
        case "dev":
            remoteUrlWithVersion =
                "https://az03-hc-app-vrpt-cmn-dev.azurewebsites.net/remoteEntry.js";
            break;
        case "TestAzure":
            remoteUrlWithVersion =
                "https://vantagetestreport.exlservice.com/remoteEntry.js";
            break;
        case "TWNP":
            remoteUrlWithVersion =
                "https://vrptcommonnp3.triwest.com/remoteEntry.js";
            break;
        case "TWPRD":
            remoteUrlWithVersion =
                "https://VantageReport.triwest.com/remoteEntry.js";
            break;
        case "qa":
            remoteUrlWithVersion =
                "https://dev-exlvantage-reporting.exlservice.com/remoteEntry.js";
           break;
        case "hznuat":
        case "maxoruat":
            remoteUrlWithVersion = "https://vantageuatreport.exlservice.com/remoteEntry.js";
            break;
        case "hznprod":
        case "maxorprod":
            remoteUrlWithVersion = "https://vantageprodreport.exlservice.com/remoteEntry.js";
            break;
        case "lktuat":
            remoteUrlWithVersion = "http://localhost:3000/remoteEntry.js";
           break;
        case "idemo":
            remoteUrlWithVersion = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktuattest":
            remoteUrlWithVersion = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktprod":
            remoteUrlWithVersion = "http://localhost:3000/remoteEntry.js";
           break;
        case "Textron":
            remoteUrlWithVersion = "https://vantagereportingreact.exlservice.com/remoteEntry.js";
            break;
        case "CHA":
            remoteUrlWithVersion = "https://vantagereportingreact.exlservice.com/remoteEntry.js";
            break;
        case "HAP":
            remoteUrlWithVersion = "https://vantagereportingreact.exlservice.com/remoteEntry.js";
            break;
        case "HP":
            remoteUrlWithVersion = "https://vantagereportingreact.exlservice.com/remoteEntry.js";
            break;
        case "Idemo":
            remoteUrlWithVersion = "https://vantagereportingreact.exlservice.com/remoteEntry.js";
            break;
        case "Cdemo":
            remoteUrlWithVersion = "https://vantagereportingreact.exlservice.com/remoteEntry.js";
            break;
        case "Maxor":
            remoteUrlWithVersion = "https://vantagereportingreactlive.exlservice.com/remoteEntry.js";
            break;
        default:
            remoteUrlWithVersion = "";
            break;
    }
    const script = document.createElement("script");
    script.src = remoteUrlWithVersion;
    script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
            get: (request) => window.REPORTING.get(request),
            init: (arg) => {
                try {
                    return window.REPORTING.init(arg);
                } catch (e) {
                    console.log("remote container already initialized");
                }
            },
        };
        resolve(proxy);
    };
    script.onerror = () => {
        reject();
        //alert("Error loading " + this.src); // Error loading https://example.com/404.js
        document.head.appendChild(null);
        //return null;
    };
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);
});