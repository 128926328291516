module.exports = new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let remoteUrlWithVersionMA;
    let cookies =  document.cookie;
    const cookieArray = cookies.split(";").map((s) => s.split("=").map((s) => s.trim())).reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    const env =cookieArray.get("VANTAGE_ENV");
    console.log("environent ", env);
    switch (env) {
        case "local":
            remoteUrlWithVersionMA = "http://localhost:3000/remoteEntry.js";
          break;
        case "dev":
            remoteUrlWithVersionMA =
                "https://az03-hc-app-vma-cmn-dev.azurewebsites.net/remoteEntry.js";
            break;
        case "TestAzure":
            remoteUrlWithVersionMA =
                "https://vantagetestma.exlservice.com/remoteEntry.js";
            break;
        case "TWNP":
            remoteUrlWithVersionMA =
                "https://vmacommonnp3.triwest.com/remoteEntry.js";
            break;
        case "TWPRD":
            remoteUrlWithVersionMA =
                "https://VantageMA.triwest.com/remoteEntry.js";
            break;
        case "qa":
            remoteUrlWithVersionMA =
                "https://qa-exlvantage-ma.exlservice.com/remoteEntry.js";
           break;
        case "hznuat":
        case "maxoruat":
            remoteUrlWithVersionMA = "https://vantageuatma.exlservice.com/remoteEntry.js";
            break;
        case "hznprod":
        case "maxorprod":
            remoteUrlWithVersionMA = "https://vantageprodma.exlservice.com/remoteEntry.js";
            break;
        case "lktuat":
            remoteUrlWithVersionMA = "http://localhost:3000/remoteEntry.js";
           break;
        case "idemo":
            remoteUrlWithVersionMA = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktuattest":
            remoteUrlWithVersionMA = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktprod":
            remoteUrlWithVersionMA = "http://localhost:3000/remoteEntry.js";
           break;
        case "Textron":
            remoteUrlWithVersionMA = "https://vantagemareact.exlservice.com/remoteEntry.js";
            break;
        case "CHA":
            remoteUrlWithVersionMA = "https://vantagemareact.exlservice.com/remoteEntry.js";
            break;
		case "HAP":
            remoteUrlWithVersionMA = "https://vantagemareact.exlservice.com/remoteEntry.js";
            break;
        case "HP":
            remoteUrlWithVersionMA = "https://vantagemareact.exlservice.com/remoteEntry.js";
            break;
        case "Idemo":
            remoteUrlWithVersionMA = "https://vantagemareact.exlservice.com/remoteEntry.js";
            break;
        case "Cdemo":
            remoteUrlWithVersionMA = "https://vantagemareact.exlservice.com/remoteEntry.js";
            break;
        case "Maxor":
            remoteUrlWithVersionMA = "https://vantagemareactlive.exlservice.com/remoteEntry.js";
            break;
        default:
            remoteUrlWithVersionMA = "";
            break;
    }
    
    // Redirect to Login Page 
    if (remoteUrlWithVersionMA === undefined) {
		//debugger
        let MVCLoginURL;
        let domain = new URL(window.location.origin);
        switch (domain.host) {
            case "devhome-infolock.lockton.com":
                MVCLoginURL = "https://dev-infolock.lockton.com";
                break;
            case "testhome-infolock.lockton.com":
                MVCLoginURL = "https://test-infolock.lockton.com";
                break;
            case "home-infolock.lockton.com":
                MVCLoginURL = "https://infolock.lockton.com";
                break;
            case "app-vhome-proddr-eastus2-01.azurewebsites.net":
                MVCLoginURL = "https://lbdp-proddr-vantage-app.azurewebsites.net";
                break;
            case "home-maxor.exlservice.com":
                MVCLoginURL = "https://maxor.exlservice.com";
                break;
            case "vantageuathorizonhome.exlservice.com":
                MVCLoginURL = "https://vantageuathorizon.exlservice.com";
                break;
			case "vantageprodhorizonhome.exlservice.com":
                MVCLoginURL = "https://vantageprodhorizon.exlservice.com";
                break;
            case "vantagetesthome.exlservice.com":
                MVCLoginURL = "https://vantagetest.exlservice.com/";
                break;
            case "app-vhomeexl-np3-t4-usaz.ase-vant-np3-t4-usaz.appserviceenvironment.us":
                MVCLoginURL = "https://app-vcoreexl-np3-t4-usaz.ase-vant-np3-t4-usaz.appserviceenvironment.us/";
                break;
            case "app-vhomeexl-prd-t4-usaz.ase-vant-np3-t4-usaz.appserviceenvironment.us":
                MVCLoginURL = "https://app-vcoreexl-prd-t4-usaz.ase-vant-np3-t4-usaz.appserviceenvironment.us/";
                break;
        }
        window.location.href = MVCLoginURL;
    }
    
    //remoteUrlWithVersionMA = "https://azvantagedev-ma.exlservice.com/remoteEntry.js";
    // remoteUrlWithVersionMA = "http://localhost:3001/remoteEntry.js";
    const script = document.createElement("script");
    script.src = remoteUrlWithVersionMA;
    script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
            get: (request) => window.MA.get(request),
            init: (arg) => {
                try {
                    return window.MA.init(arg);
                } catch (e) {
                    console.log("remote container already initialized");
                }
            },
        };
        resolve(proxy);
    };
    script.onerror = () => {
        reject();
        //alert("Error loading " + this.src); // Error loading https://example.com/404.js
        document.head.appendChild(null);
        //return null;
    };
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);
});