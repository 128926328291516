module.exports = new Promise ((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let remoteUrlWithVersionAS;

    remoteUrlWithVersionAS = "http://localhost:3003/remoteEntry.js";//"https://qa-exlvantage-AS.exlservice.com/remoteEntry.js";
    const script = document.createElement("script");
    let cookies = document.cookie;
    const cookieArray = cookies.split(";").map((s) => s.split("=").map((s) => s.trim())).reduce((m, [k, v]) => (m.set(k, v), m), new Map());
    const env = cookieArray.get("VANTAGE_ENV");
    console.log("environent ", env);

    switch (env) {
        case "local":
            remoteUrlWithVersionAS = "http://localhost:3000/remoteEntry.js";
            break;
        case "dev":
            remoteUrlWithVersionAS =
                "https://az03-hc-app-accs-cmn-dev.azurewebsites.net/remoteEntry.js";
            break;
        case "qa":
            remoteUrlWithVersionAS =
                "https://qa-exlvantage-AS.exlservice.com/remoteEntry.js";
            break;
        case "uat":
            remoteUrlWithVersionAS = "http://localhost:3000/remoteEntry.js";
            break;
        case "lktuat":
            remoteUrlWithVersionAS = "http://localhost:3000/remoteEntry.js";
            break;
        case "idemo":
            remoteUrlWithVersionAS = "http://localhost:3000/remoteEntry.js";
            break;
        case "lktuattest":
            remoteUrlWithVersionAS = "http://localhost:3000/remoteEntry.js";
            break;
        case "lktprod":
            remoteUrlWithVersionAS = "http://localhost:3000/remoteEntry.js";
            break;
        default:
            remoteUrlWithVersionAS = "";
            break;
    }
    script.src = remoteUrlWithVersionAS;
    script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
            get: (request) => window.AS.get(request),
            init: (arg) => {
                try {
                    return window.AS.init(arg);
                } catch (e) {
                    console.log("remote container already initialized");
                }
            },
        };
        resolve(proxy);
    };
    script.onerror = () => {
        reject();
        //alert("Error loading " + this.src); // Error loading https://example.com/404.js
        document.head.appendChild(null);
        //return null;
    };
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);
});